import { HiringRequestFormContainerProps } from '../ImportForm/types';
import React from 'react';
import { HiringRequestForm } from './HiringRequestForm';
import {
  useCheckForExistingHiringRequest,
  useQueryCheckIfCandidateHasApplication,
} from '../ImportForm/queries';
import { ExistingAppDisplay } from './ExistingAppDisplay';
import { CircularProgress } from '@mui/material';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { Alert } from 'sharedComponents/Alert';
import { useFormContext } from 'react-hook-form';
import auth from 'utils/auth';
import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';

export const HiringRequestFormContainer: React.FC<HiringRequestFormContainerProps> = ({
  candidateId,
  candidateName,
  candidateEmail,
}) => {
  const user = auth.getUser();
  const districtId: number | undefined = user?.profile?.district?.id;

  const { watch } = useFormContext();
  const role = watch('role');

  const districtRoleId = role?.district_role?.id;

  const {
    data: existingAppData,
    isLoading: isExistingAppLoading,
    isError: isExistingAppError,
    error: existingAppError,
  } = useQueryCheckIfCandidateHasApplication(candidateId, districtRoleId, Boolean(districtRoleId));
  const existingAppErrorMessage = getAxiosErrorMessageByStatusCode(existingAppError);

  const hiringRequestQueryEnabled =
    Boolean(role) && Boolean(districtId) && auth.hasConnectCandidateProcessing();

  const {
    data: existingHiringRequestData,
    isLoading: isExistingHiringRequestLoading,
    isError: isExistingHiringRequestError,
    error: existingHiringRequestError,
  } = useCheckForExistingHiringRequest(districtId, candidateId, {
    enabled: hiringRequestQueryEnabled,
  });
  const existingHiringRequestErrorMessage = getAxiosErrorMessageByStatusCode(
    existingHiringRequestError
  );

  if (!role) return null;

  const isLoading =
    isExistingAppLoading || (isExistingHiringRequestLoading && hiringRequestQueryEnabled);
  const isError = isExistingAppError || isExistingHiringRequestError;

  if (isLoading) {
    return (
      <CircularProgress
        data-testid={ATSConnectImportCandidateFormTestIds.HIRING_REQUEST_FORM_LOADER}
      />
    );
  }

  if (isError) {
    const errorMessage =
      existingAppErrorMessage || existingHiringRequestErrorMessage || 'Something went wrong';
    return (
      <Alert
        type="error"
        dataTestId={ATSConnectImportCandidateFormTestIds.HIRING_REQUEST_FORM_ALERT}
      >
        {errorMessage}
      </Alert>
    );
  }

  if (hiringRequestQueryEnabled && existingHiringRequestData.has_existing_hiring_request) {
    return <ExistingAppDisplay existingEntity="hiringRequest" candidateEmail={candidateEmail} />;
  }

  if (existingAppData.has_existing_app) {
    return <ExistingAppDisplay existingEntity="application" candidateEmail={candidateEmail} />;
  }

  return <HiringRequestForm candidateName={candidateName} />;
};
