import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import { CTAButtonContainer, Key, SubContainer } from '../ImportForm/styles';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { useHistory } from 'react-router';
import { useFormContext } from 'react-hook-form';
import auth from 'utils/auth';
import {
  ExistingAppDisplayProps,
  ExistingEntity,
  ExistingEntityContent,
} from '../ImportForm/types';

const getTextContentAndTestIds = (entity: ExistingEntity): ExistingEntityContent => {
  const entityToTextContent: { [k in ExistingEntity]: ExistingEntityContent } = {
    application: {
      section: {
        testId: ATSConnectImportCandidateFormTestIds.CANDIDATE_HAS_ALREADY_APPLIED_SECTION,
      },
      title: {
        testId: ATSConnectImportCandidateFormTestIds.EXISTING_APPLICATION_TITLE,
        text: "It looks like this candidate already has an active application for this role. Here's what you can do next:",
      },
      firstBullet: {
        testId: ATSConnectImportCandidateFormTestIds.EXISTING_APPLICATION_FIRST_BULLET,
        text: 'View other candidates for this role here in Nimble by clicking the button below',
      },
      secondBullet: {
        testId: ATSConnectImportCandidateFormTestIds.EXISTING_APPLICATION_SECOND_BULLET,
        text: "If you can't find this candidate's application, contact your HR team for more information on where the candidate is in the hiring process.",
      },
    },
    hiringRequest: {
      section: {
        testId: ATSConnectImportCandidateFormTestIds.CANDIDATE_HAS_EXISTING_HIRING_REQUEST_SECTION,
      },
      title: {
        testId: ATSConnectImportCandidateFormTestIds.EXISTING_HIRING_REQUEST_TITLE,
        text: "This candidate is part of an active hiring request for another school or role in your district. Here's what you can do next:",
      },
      firstBullet: {
        testId: ATSConnectImportCandidateFormTestIds.EXISTING_HIRING_REQUEST_FIRST_BULLET,
        text: 'Contact your HR team to 1) let them know you have a role you would like to hire this candidate for and 2) get information on their existing hiring request and possible next steps.',
      },
      secondBullet: {
        testId: ATSConnectImportCandidateFormTestIds.EXISTING_HIRING_REQUEST_SECOND_BULLET,
        text: 'View other candidates for this role here in Nimble by clicking the button below',
      },
    },
  };

  return entityToTextContent[entity];
};

export const ExistingAppDisplay: React.FC<ExistingAppDisplayProps> = ({
  existingEntity,
  candidateEmail,
}) => {
  const history = useHistory();

  const { watch } = useFormContext();
  const role = watch('role');

  const handleViewCandidates = () => {
    let candidateUrl = `/school/candidates?query=${candidateEmail}`;
    if (auth.hasConnectCandidateProcessing()) {
      candidateUrl = `/school/requisition-view/${role.id}`;
    }

    history.push(candidateUrl);
  };

  const textContentAndTestIds = getTextContentAndTestIds(existingEntity);

  return (
    <>
      <SubContainer data-testid={textContentAndTestIds.section.testId}>
        <Key data-testid={textContentAndTestIds.title.testId}>
          {textContentAndTestIds.title.text}
        </Key>
        <li data-testid={textContentAndTestIds.firstBullet.testId}>
          {textContentAndTestIds.firstBullet.text}
        </li>
        <li data-testid={textContentAndTestIds.secondBullet.testId}>
          {textContentAndTestIds.secondBullet.text}
        </li>
      </SubContainer>
      <CTAButtonContainer>
        <PrimaryButton
          dataTestId={ATSConnectImportCandidateFormTestIds.VIEW_CANDIDATES_BUTTON}
          onClick={handleViewCandidates}
        >
          View Candidates
        </PrimaryButton>
      </CTAButtonContainer>
    </>
  );
};
