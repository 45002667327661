import { Box, Stack, styled, Theme, Typography, useMediaQuery } from '@mui/material';
import { ConnectCandidateMatchDashboardPaginationHeaderDataTestIds } from 'data-testids/ConnectDataTestIds';
import { MobilePaginationButtonGroup } from 'sharedComponents/Pagination/MobilePaginationButtonGroup';
import { PaginationButtonGroup } from 'sharedComponents/Pagination/PaginationButtonGroup';
import { CandidateMatchDashboardPaginationHeaderProps } from './utils/types';
export const CandidateMatchDashboardPaginationHeader: React.FC<
  CandidateMatchDashboardPaginationHeaderProps
> = ({
  onChange,
  hasPendingMatches,
  totalPages,
  currentMatch,
  schoolName,
  isEndOfMatches,
  displayTotal,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <HeaderContainer
      data-testid={ConnectCandidateMatchDashboardPaginationHeaderDataTestIds.HEADER_CONTAINER}
    >
      {isMobile ? (
        <Stack direction="column" spacing={2} width="100%" alignItems="center" px={2}>
          <MobileSchoolName>{schoolName}</MobileSchoolName>
          <MobilePaginationButtonGroup
            totalPages={totalPages}
            currentPage={currentMatch}
            onChange={onChange}
            dataTestId={
              ConnectCandidateMatchDashboardPaginationHeaderDataTestIds.PAGINATION_BUTTON_GROUP
            }
            isDisabled={!hasPendingMatches}
          />
        </Stack>
      ) : (
        <>
          <MatchTitleTextContainer>
            <MatchTitleText>
              {!hasPendingMatches && !isEndOfMatches ? (
                ''
              ) : isEndOfMatches ? (
                'End of matches'
              ) : (
                <>
                  Match {currentMatch} of {displayTotal} : {schoolName}
                </>
              )}
            </MatchTitleText>
          </MatchTitleTextContainer>
          <PaginationButtonGroup
            totalPages={totalPages}
            currentPage={currentMatch}
            shouldHideLocationText={!hasPendingMatches}
            onChange={onChange}
            dataTestId={
              ConnectCandidateMatchDashboardPaginationHeaderDataTestIds.PAGINATION_BUTTON_GROUP
            }
            isDisabled={!hasPendingMatches}
          />
        </>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  minWidth: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  padding: '0px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));

const MatchTitleTextContainer = styled(Box)(({ theme }) => ({
  display: 'inline',
  width: 'auto',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const MatchTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '119.4%',
  display: 'inline',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('md')]: {
    whiteSpace: 'wrap',
  },
}));

const MobileSchoolName = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '119.4%',
}));
