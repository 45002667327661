import { TextField, Typography, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CTAButtonContainer, HelpText, SubContainer } from '../ImportForm/styles';
import { Key } from '../ImportForm/styles';
import { SelectAllListWithRHF } from 'sharedComponents/List/SelectAllListWithRHF';
import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { contactOptions } from './data';

export const HiringRequestForm: React.FC<{ candidateName: string }> = ({ candidateName }) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const hasFormErrors = Boolean(errors.role || errors.contactMethods);

  return (
    <>
      <SubContainer>
        <Key>How have you communicated with this candidate about this role thus far?</Key>
        <HelpText sx={{ paddingBottom: theme.spacing(1) }}>Select all that apply</HelpText>
        <SelectAllListWithRHF
          dataTestId={ATSConnectImportCandidateFormTestIds.CONTACT_OPTIONS}
          formValue="contactMethods"
          itemKey="label"
          items={contactOptions}
          rules={{ required: 'Please select at least one contact method' }}
          label={
            <Typography
              sx={{
                '.MuiTypography-root': {
                  fontWeight: theme.typography.fontWeightMediumBold,
                },
              }}
            >
              Select all
            </Typography>
          }
        />
        {errors && (
          <Typography sx={{ color: theme.palette.error.main }}>
            {errors?.contactMethods?.message}
          </Typography>
        )}
      </SubContainer>

      <SubContainer
        data-testid={
          ATSConnectImportCandidateFormTestIds.CANDIDATE_HAS_NOT_APPLIED_INTERNAL_NOTE_SECTION
        }
      >
        <Key>Tell us a little bit about your interaction with the candidate so far:</Key>
        <Controller
          name="internalNote"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              size="small"
              onChange={onChange}
              value={value}
              fullWidth
              variant="outlined"
              multiline
              rows={6}
              placeholder={`E.g. I have spoken on the phone twice with ${candidateName}. I was impressed by their qualifications, especially their experience in Title I schools.`}
            />
          )}
        />
      </SubContainer>

      <CTAButtonContainer>
        <PrimaryButton
          type="submit"
          disabled={hasFormErrors}
          dataTestId={ATSConnectImportCandidateFormTestIds.IMPORT_CANDIDATE_BUTTON}
        >
          Submit Hiring Request
        </PrimaryButton>
      </CTAButtonContainer>
    </>
  );
};
