import { Box } from '@mui/material';
import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import { ModalContainer, Title, Form, SubContainer, Row, Key } from '../ImportForm/styles';
import { LandingFormProps } from '../ImportForm/types';
import { HiringRequestFormContainer } from './HiringRequestFormContainer';
import { SelectRoleSubContainer } from './SelectRoleSubcontainer';

export const LandingForm: React.FC<LandingFormProps> = ({
  candidateEmail,
  candidateId,
  candidateName,
  onSubmit,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ModalContainer sx={{ width: 'inherit' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title variant="h1" data-testid={ATSConnectImportCandidateFormTestIds.TITLE}>
            Request to Hire {candidateName}
          </Title>
        </Box>
        <SubContainer>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.CANDIDATE_NAME}>
            <Key>Candidate:</Key> &nbsp;{candidateName}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.CANDIDATE_EMAIL}>
            <Key>Candidate email:</Key> &nbsp; {candidateEmail}
          </Row>
        </SubContainer>
        <SelectRoleSubContainer />
        <HiringRequestFormContainer
          candidateId={candidateId}
          candidateName={candidateName}
          candidateEmail={candidateEmail}
        />
      </ModalContainer>
    </Form>
  );
};
