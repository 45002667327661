import { useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxListProps } from './types';

export const CheckboxList: React.FC<CheckboxListProps> = ({
  disableRipple,
  dataTestId,
  isDense,
  isReadOnly,
  options,
  listSx,
  disableGutters,
  listItemButtonSx,
  listItemIconSx,
  onChange,
}) => {
  const [checked, setChecked] = useState([0]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List sx={listSx} data-testid={dataTestId}>
      {options?.map((option) => {
        return (
          <ListItem key={option.id} disableGutters={disableGutters}>
            <ListItemButton
              disableRipple={disableRipple}
              onClick={isReadOnly ? null : handleToggle(option.id)}
              dense={isDense}
              sx={listItemButtonSx}
            >
              <ListItemIcon sx={listItemIconSx}>
                <Checkbox
                  edge="start"
                  checked={checked.includes(option.id) || option.isChecked}
                  tabIndex={-1}
                  disabled={isReadOnly}
                  data-testid={`checkbox-list-checkbox-${option.id}`}
                  onChange={onChange}
                />
              </ListItemIcon>
              <ListItemText primary={option.title} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
