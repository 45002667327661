import { Link, useTheme } from '@mui/material';
import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorText, HelpText, Key, SubContainer } from '../ImportForm/styles';
import { JobComboBox } from './JobComboBox';
import { useEffect, useState } from 'react';
import { useQuerySearchActiveRoles } from '../ImportForm/queries';
import { useHistory } from 'react-router';

export const SelectRoleSubContainer: React.FC = () => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const history = useHistory();

  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue);

  const { data: options, isLoading: isDataLoading } = useQuerySearchActiveRoles(
    debouncedInputValue,
    {
      enabled: debouncedInputValue.length > 0,
      keepPreviousData: true,
      staleTime: 60000,
      cacheTime: 120000,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  const isLoading = isDataLoading && Boolean(inputValue);

  const handleInputChange = (text: string) => {
    setInputValue(text);
  };

  return (
    <SubContainer>
      <Key>What role do you want to hire this candidate for?</Key>
      <HelpText sx={{ paddingBottom: theme.spacing(1) }}>
        Don&apos;t see the role you want? Contact your administrator for more information or &nbsp;
        <Link sx={{ cursor: 'pointer' }} onClick={() => history.push('/school/jobcreate')}>
          submit a job request
        </Link>
        .
      </HelpText>
      <Controller
        name="role"
        control={control}
        rules={{ required: 'Please select a role' }}
        render={({ field: { onChange } }) => (
          <JobComboBox
            data-testid={ATSConnectImportCandidateFormTestIds.JOB_COMBOBOX}
            onChange={onChange}
            hasError={Boolean(errors.role)}
            onInputChange={handleInputChange}
            isLoading={isLoading}
            options={options}
            inputValue={inputValue}
          />
        )}
      />
      {errors.role && <ErrorText>{errors.role.message}</ErrorText>}
    </SubContainer>
  );
};
