import { InputLabel } from '@mui/material';
import { LabelProps } from './types';

export const Label: React.FC<LabelProps> = ({ dataTestId, labelText, sx }) => {
  return (
    <InputLabel sx={sx} shrink={false} data-testid={dataTestId}>
      {labelText}
    </InputLabel>
  );
};
