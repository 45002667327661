import { Input } from 'sharedComponents/Input';
import { Label } from 'sharedComponents/Label';
import { InputProps } from './types';

export const InputAndLabel: React.FC<InputProps> = ({
  hasError,
  label,
  onChange,
  placeholder,
  testid,
  value,
  sx,
  multiline,
  labelSx,
  defaultValue,
  rows,
  InputProps,
}) => {
  return (
    <>
      <Label labelText={label} sx={labelSx} />
      <Input
        hasError={hasError}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        dataTestId={testid}
        sx={sx}
        multiline={multiline}
        defaultValue={defaultValue}
        rows={rows}
        InputProps={InputProps}
      />
    </>
  );
};
